<template>
    <el-drawer
        :title="activeRule ? activeRule.name : '渠道配置'"
        size="900px"
        :visible.sync="isShow"
        :before-close="onClose"
        :destroy-on-close="true"
        @opened="onOpened"
        class="custom_drawer"
    >
        <el-form ref="formData" :model="formData" :rules="rules" label-width="80px">
            <el-form-item v-if="!activeRule" label="快速选择">
                <el-select
                    @change="onHistoryRuleChange"
                    filterable
                    v-model="currentHistoryRule"
                    placeholder="选择历史策略"
                >
                    <el-option
                        v-for="item in historyRule"
                        :key="item.id"
                        :label="`${item.day} ${item.strategy}`"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="规则名称" prop="name">
                <el-input
                    v-model.trim="formData.name"
                    :maxlength="32"
                    show-word-limit
                    :disabled="activeDay === 'history'"
                    placeholder="请输入规则名称"
                />
            </el-form-item>
            <el-form-item label="投放渠道" prop="providerNoData">
                <div class="category_item" v-for="category in categoryProviderList" :key="category.id">
                    <div class="category_container">
                        <div class="category_name">{{ category.name }}:</div>
                        <el-checkbox-group v-model="formData.providerNoData" :disabled="activeDay === 'history'">
                            <template v-for="item in category.children">
                                <el-checkbox :disabled="item.disabled" :label="item.providerNo" :key="item.providerNo">
                                    <span :style="`color: ${item.state === 0 ? '#f00' : ''}`">{{ item.name }}</span>
                                </el-checkbox>
                            </template>
                        </el-checkbox-group>
                    </div>
                    <div class="opts" v-if="activeDay !== 'history'">
                        <el-link type="primary" plain size="mini" @click="onSelectAllCategory(category)">
                            全部选择
                        </el-link>
                        <el-link type="info" plain size="mini" @click="onUnSelectAllCategory(category)">
                            取消选择
                        </el-link>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="运营/名片">
                <div v-for="group in groupList" :key="group.id">
                    <div class="group_name">{{ group.name }}</div>
                    <div class="staff_list">
                        <template v-for="item in staffList">
                            <el-input
                                @change="onCntChange(item)"
                                v-if="item.groupId === group.id"
                                :key="item.id"
                                placeholder="名片"
                                v-model="item.cnt"
                                size="mini"
                                :disabled="activeDay === 'history'"
                                :class="item.oldCnt !== item.cnt ? 'update_cnt' : ''"
                            >
                                <template slot="prepend">
                                    <StaffName :staff="item" />
                                </template>
                                <i
                                    v-if="!item.attendance"
                                    slot="suffix"
                                    class="el-input__icon el-icon-close-notification"
                                />
                            </el-input>
                        </template>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div class="drawer_footer" v-if="activeDay !== 'history'">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { getProviderCategoryList } from '@/js/api/advertApi.js'
import { oppoAssignRuleAdd, oppoAssignRuleMod, getOppoAssignRule } from '@/js/api/saleApi.js'
import dayjs from 'dayjs'

export default {
    props: ['isShow', 'disabledProvider', 'activeDay', 'activeRule', 'otherParams'],
    data() {
        return {
            historyRule: [],
            currentHistoryRule: '',

            groupList: [],
            staffList: [],
            categoryProviderList: [],
            formData: {
                name: '',
                providerNoData: [],
            },
            rules: {
                name: [{ required: true, message: '请输入规则名称', trigger: 'blur' }],
                providerNoData: [{ required: true, message: '请选择渠道', trigger: 'blur' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.currentHistoryRule = ''
            const activeRuleProvider = []
            const activeStaffCntMap = {}
            // 初始化渠道
            if (this.activeRule) {
                this.activeRule.providers.forEach((item) => {
                    activeRuleProvider.push(item.providerNo)
                })
                this.formData.providerNoData = activeRuleProvider
                this.formData.name = this.activeRule.name

                this.activeRule.details.forEach((item) => {
                    activeStaffCntMap[`s_${item.staffId}`] = item.cnt || 0
                })
            }
            // 初始化运营
            const staffList = []
            const groupMap = {}
            this.otherParams.staffList.forEach((item) => {
                if (item.group && item.state === 0) {
                    staffList.push({
                        id: item.id,
                        name: item.name,
                        cnt: activeStaffCntMap[`s_${item.id}`] || 0,
                        oldCnt: activeStaffCntMap[`s_${item.id}`] || 0,
                        groupId: item.group.id,
                        groupName: item.group.name,
                        groupSeq: item.group.seq,
                        username: item.username,
                        attendance: true,
                    })
                    groupMap[item.group.id] = {
                        ...item.group,
                    }
                }
            })
            this.groupList = Object.values(groupMap)
            this.staffList = staffList
            // 初始化渠道
            this.getProviderCategoryListApi()

            // 新建时，查询前3天数据  快速配置
            this.getOppoAssignRuleApi()
        },
        onClose() {
            this.$refs.formData.resetFields()
            this.$emit('onClose')
        },
        onHistoryRuleChange() {
            for (let index = 0; index < this.historyRule.length; index++) {
                const item = this.historyRule[index]
                if (item.id === this.currentHistoryRule) {
                    // 设置渠道数据
                    const providerNoMap = {}
                    if (item.providers && item.providers.length !== 0) {
                        item.providers.forEach((item) => {
                            providerNoMap[item.providerNo] = item.providerNo
                        })
                    }
                    const providerNos = []
                    this.categoryProviderList.forEach((category) => {
                        category.children.forEach((item) => {
                            if (!item.disabled && providerNoMap[item.providerNo]) {
                                providerNos.push(item.providerNo)
                            }
                        })
                    })
                    this.formData.providerNoData = providerNos
                    this.formData.name = item.name || ''
                    // 设置运营名片
                    const staffCardMap = {}
                    if (item.details && item.details.length !== 0) {
                        item.details.forEach((item) => {
                            // 过滤离职员工
                            if (this.otherParams.staffMap[item.staffId].state === 0) {
                                staffCardMap[`s_${item.staffId}`] = item.cnt
                            }
                        })
                    }
                    this.staffList.forEach((staff) => {
                        staff.cnt = staffCardMap[`s_${staff.id}`] || 0
                    })
                    break
                }
            }
        },
        onSelectAllCategory(category) {
            category.children.forEach((item) => {
                const { disabled, providerNo } = item
                if (!disabled && !this.formData.providerNoData.includes(providerNo)) {
                    this.formData.providerNoData.push(item.providerNo)
                }
            })
        },
        onUnSelectAllCategory(category) {
            category.children.forEach((item) => {
                const index = this.formData.providerNoData.indexOf(item.providerNo)
                if (!item.disabled && index > -1) {
                    this.formData.providerNoData.splice(index, 1)
                }
            })
        },
        onCntChange(staff) {
            staff.cnt = isNaN(parseInt(staff.cnt, 10)) ? 0 : parseInt(staff.cnt, 10)
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    const providers = []
                    const details = []
                    this.formData.providerNoData.forEach((item) => {
                        providers.push({ providerNo: item })
                    })
                    this.staffList.forEach((staff) => {
                        details.push({ staffId: staff.id, cnt: staff.cnt || 0 })
                    })
                    const params = {
                        projectNo: this.otherParams.projectNo,
                        name: this.formData.name,
                        day: this.activeDay,
                        providers,
                        details,
                    }
                    if (this.activeRule) {
                        params.id = this.activeRule.ruleId
                        oppoAssignRuleMod(params).then(() => {
                            this.$message.success('编辑成功')
                            this.$refs.formData.resetFields()
                            this.$emit('onClose', true)
                        })
                    } else {
                        oppoAssignRuleAdd(params).then(() => {
                            this.$message.success('编辑成功')
                            this.$refs.formData.resetFields()
                            this.$emit('onClose', true)
                        })
                    }
                }
            })
        },
        getOppoAssignRuleApi() {
            getOppoAssignRule({
                projectNo: this.otherParams.projectNo,
                dayStart: dayjs().subtract(10, 'days').format('YYYY-MM-DD'),
                dayEnd: dayjs().format('YYYY-MM-DD'),
                page: 1,
                orderBy: '-day',
            }).then((res) => {
                if (res.data.length !== 0) {
                    let currentDate = res.data[0]['day']
                    res.data.forEach((item) => {
                        if (currentDate !== item.day) {
                            currentDate = item.day
                        }
                        item.strategy = item.name
                    })
                }
                this.historyRule = res.data
            })
        },
        async getProviderCategoryListApi() {
            const categoryNos = []
            const categoryNoMap = {}
            this.otherParams.providerList.forEach((item) => {
                if (!categoryNos.includes(item.categoryNo)) {
                    categoryNos.push(item.categoryNo)
                }
                // 已选择渠道 禁止操作
                const provider = { ...item, disabled: this.disabledProvider.includes(item.providerNo) }
                // 修改时，将选中策略，已配置渠道释放，可以操作
                if (this.formData.providerNoData.length !== 0) {
                    if (this.formData.providerNoData.includes(item.providerNo)) {
                        provider.disabled = false
                    }
                }
                if (!categoryNoMap[item.categoryNo]) {
                    categoryNoMap[item.categoryNo] = []
                }
                categoryNoMap[item.categoryNo].push(provider)
            })
            const res1 = await getProviderCategoryList({ categoryNos: categoryNos })
            res1.data.forEach((item) => {
                item.children = categoryNoMap[item.categoryNo]
            })
            this.categoryProviderList = res1.data
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .staff_list {
    display: flex;
    flex-wrap: wrap;
    .el-input {
        flex-basis: 22%;
        margin-right: 20px;
        margin-bottom: 12px;
        .el-input-group__prepend {
            width: 64px;
            padding: 0;
            text-align: center;
        }
    }
    .update_cnt {
        .el-input-group__prepend {
            border-color: #409eff;
            color: #409eff;
        }
        .el-input__inner {
            border-color: #409eff;
        }
    }
    .el-input__icon {
        color: #bf0000;
    }
}
.category_item {
    border-bottom: 1px solid #efefef;
    &:last-child {
        border: none;
    }
    .opts {
        margin: 0 0 0 80px;
        .el-link {
            margin-right: 20px;
        }
    }
    .category_container {
        display: flex;
        align-items: center;
    }
    .category_name {
        font-size: 12px;
        font-weight: 700;
        margin-right: 12px;
        min-width: 60px;
        flex-shrink: 0;
        text-align: right;
        cursor: pointer;
    }
}
.group_name {
    font-size: 12px;
    margin-top: 20px;
    font-weight: 700;
    margin-left: 12px;
    &:first-child {
        margin-top: 0;
    }
}
</style>
