<template>
    <div>
        <el-table
            :key="`key-${historyDate}`"
            row-key="uuid"
            @sort-change="onSortTable"
            :data="tableData"
            :border="true"
            highlight-current-row
            v-if="tableHeader.length !== 0"
        >
            <el-table-column fixed header-align="center" prop="title" label="运营" width="130">
                <template slot-scope="scope">
                    <StaffName v-if="scope.row.staffId" :staff="scope.row.staff" />
                    <span v-else>{{ scope.row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="totalCnt" label="总名片" width="100" sortable="custom">
                <template slot-scope="scope">
                    <span>{{ scope.row.totalCnt }}</span>
                </template>
            </el-table-column>

            <el-table-column
                sortable="custom"
                :prop="`rule_${provider.ruleId}`"
                v-for="provider in tableHeader"
                :key="provider.key"
                align="center"
            >
                <template slot="header">
                    <el-tooltip content="Bottom center" placement="top" effect="light">
                        <div slot="content" class="provider_list">
                            <span v-for="pItem in provider.providers" :key="pItem.id">
                                {{ otherParams.providerMap[pItem.providerNo] }}
                            </span>
                        </div>
                        <i class="el-icon-more" />
                    </el-tooltip>
                    <span class="edit_btn" @click.stop="onShowEditOppoConfig(provider)">
                        {{ provider.name }}
                    </span>
                </template>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row[`rule_${provider.ruleId}`]['cnt'] }}/{{
                            scope.row[`rule_${provider.ruleId}`]['assignedCnt']
                        }}
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="empty" v-else>
            <el-empty description="暂无数据"></el-empty>
        </div>
    </div>
</template>
<script>
import { getOppoAssignRule } from '@/js/api/saleApi.js'
import { v4 as uuidv4 } from 'uuid'

export default {
    props: ['historyDate', 'otherParams'],
    data() {
        return {
            tableData: [],
            tableHeader: [],
        }
    },
    created() {
        if (this.historyDate) {
            this.getOppoAssignRuleApi()
        } else {
            this.tableData = []
            this.tableHeader = []
        }
    },
    methods: {
        onShowEditOppoConfig(rule) {
            this.$emit('showConfig', rule)
        },
        // 历史数据查看
        getOppoAssignRuleApi() {
            this.tableData = []
            this.tableHeader = []

            getOppoAssignRule({
                projectNo: this.otherParams.projectNo,
                dayStart: this.historyDate,
                dayEnd: this.historyDate,
                page: 1,
            }).then((res) => {
                // 设置表头
                const _tableHeader = []
                res.data.forEach((item, index) => {
                    const data = {
                        key: `provider_${index}`,
                        ruleId: item.id,
                        name: item.name,
                        providers: item.providers,
                        details: item.details || [],
                    }
                    _tableHeader.push(data)
                })

                // 提取数据，合并运营团队
                const _staffDataMap = {}
                const _groupMap = {}
                res.data.forEach((item) => {
                    if (item.details && item.details.length !== 0) {
                        item.details.forEach((detail) => {
                            const _staff = this.otherParams.staffMap[detail.staffId]
                            const _staffGroup = _staff.group || { id: -1, name: '-', seq: 999 }
                            if (!_groupMap[_staffGroup.id]) {
                                _groupMap[_staffGroup.id] = {
                                    type: 'group',
                                    uuid: uuidv4(),
                                    groupId: _staffGroup.id,
                                    seq: _staffGroup.seq,
                                    title: _staffGroup.name,
                                    totalCnt: 0,
                                    totalAssignedCnt: 0,
                                    children: [],
                                }
                            }
                            _groupMap[_staffGroup.id][`rule_${item.id}`] = {
                                assignedCnt: 0,
                                cnt: 0,
                            }

                            // 运营数据整理
                            if (!_staffDataMap[detail.staffId]) {
                                _staffDataMap[detail.staffId] = {
                                    staff: _staff,
                                    staffId: detail.staffId,
                                    title: _staff.name,
                                    groupId: _staffGroup.id,
                                    totalCnt: 0,
                                    totalAssignedCnt: 0,
                                    uuid: uuidv4(),
                                    type: 'staff',
                                }
                            }

                            _staffDataMap[detail.staffId][`rule_${item.id}`] = {
                                ruleId: item.id,
                                cnt: detail.cnt,
                                assignedCnt: detail.assignedCnt,
                            }
                            _staffDataMap[detail.staffId]['totalCnt'] += detail.cnt
                            _staffDataMap[detail.staffId]['totalAssignedCnt'] += detail.assignedCnt
                        })
                    }
                })

                const _totalRow = { type: 'all', uuid: uuidv4(), title: '总计', totalCnt: 0, totalAssignedCnt: 0 }
                Object.values(_staffDataMap).forEach((staff) => {
                    _groupMap[staff.groupId]['children'].push(staff)
                    _groupMap[staff.groupId]['totalCnt'] += staff.totalCnt
                    _groupMap[staff.groupId]['totalAssignedCnt'] += staff.totalAssignedCnt

                    _tableHeader.forEach((rule) => {
                        const _key = `rule_${rule.ruleId}`
                        if (!_groupMap[staff.groupId][_key]) {
                            _groupMap[staff.groupId][_key] = { cnt: 0, assignedCnt: 0 }
                        }
                        if (!staff[_key]) {
                            staff[_key] = { cnt: 0, assignedCnt: 0 }
                        }
                        _groupMap[staff.groupId][_key]['cnt'] += staff[_key]['cnt']
                        _groupMap[staff.groupId][_key]['assignedCnt'] += staff[_key]['assignedCnt']

                        if (!_totalRow[_key]) {
                            _totalRow[_key] = { assignedCnt: 0, cnt: 0 }
                        }
                        _totalRow[_key]['cnt'] += staff[_key]['cnt']
                        _totalRow[_key]['assignedCnt'] += staff[_key]['assignedCnt']
                    })

                    _totalRow.totalCnt += staff.totalCnt
                    _totalRow.totalAssignedCnt += staff.totalAssignedCnt
                })
                const _tableList = Object.values(_groupMap)
                _tableList.sort((a, b) => a.seq - b.seq)
                _tableList.unshift(_totalRow)

                this.tableHeader = _tableHeader
                this.tableData = _tableList
            })
        },
        onSortTable(column) {
            if (column.order) {
                this.tableData.forEach((item) => {
                    if (item.children) {
                        item.children.sort((a, b) => {
                            let aCnt = a[column.prop]['cnt']
                            let bCnt = b[column.prop]['cnt']
                            if (column.prop === 'totalCnt') {
                                aCnt = a[column.prop]
                                bCnt = b[column.prop]
                            }
                            return column.order === 'descending' ? bCnt - aCnt : aCnt - bCnt
                        })
                    }
                })
            } else {
                this.getOppoAssignRuleApi()
            }
        },
    },
}
</script>
<style lang="less" scoped>
.el-date-editor {
    margin: 0 0 10px 10px;
}
</style>
