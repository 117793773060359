<template>
    <div class="page_container">
        <el-tabs v-model="activeDay">
            <el-tab-pane label="历史配置" name="history">
                <div class="opts">
                    <ProjectSelect @change="onProjectNoChange" />
                    <el-date-picker
                        v-model="historyDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                    />
                </div>
                <HistoryConfig
                    :otherParams="{ projectNo: projectNo, staffMap: staffMap, providerMap: providerMap }"
                    :key="`${projectNo}-${historyDate}`"
                    v-if="activeDay === 'history'"
                    :historyDate="historyDate"
                    @showConfig="onShowEditOppoConfig"
                />
            </el-tab-pane>
            <el-tab-pane v-for="day in dayList" :key="day.day" :name="day.day">
                <div slot="label">
                    <span class="today_tab" v-if="day.isToday">今</span>
                    <span>{{ day.day }}</span>
                </div>
                <template v-if="activeDay === day.day && staffMap && providerMap">
                    <div class="opts">
                        <ProjectSelect @change="onProjectNoChange" />
                        <el-button type="primary" @click="isShowEditOppoConfig = true"> 新增配置 </el-button>
                    </div>
                    <OppoConfigData
                        :key="projectNo"
                        :otherParams="{ projectNo: projectNo, staffMap: staffMap, providerMap: providerMap }"
                        :today="day.isToday"
                        :defaultDeta="day.day"
                        :tableUpdateKey="tableUpdateKey"
                        @showConfig="onShowEditOppoConfig"
                        @updateProvider="onUpdatedisabledProvider"
                    />
                </template>
            </el-tab-pane>
            <el-tab-pane label="分配规则" name="rule">
                <RuleDetails />
            </el-tab-pane>
        </el-tabs>
        <EditOppoConfig
            :otherParams="{
                projectNo: projectNo,
                staffMap: staffMap,
                staffList: staffList,
                providerList: providerList,
            }"
            :isShow="isShowEditOppoConfig"
            :activeRule="activeRule"
            :activeDay="activeDay"
            :disabledProvider="disabledProvider"
            @onClose="onCloseEditOppoConfig"
        />
    </div>
</template>
<script>
import { xbProviderList } from '@/js/api/advertApi.js'
import { getStaffList } from '@/js/api/staffApi.js'
import ProjectSelect from '@/views/components/ProjectSelect'
import RuleDetails from './components/oppoConfig/RuleDetails.vue'
import OppoConfigData from './components/oppoConfig/OppoConfigData.vue'
import HistoryConfig from './components/oppoConfig/HistoryConfig.vue'
import EditOppoConfig from './components/oppoConfig/EditOppoConfig.vue'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

export default {
    components: { ProjectSelect, RuleDetails, OppoConfigData, HistoryConfig, EditOppoConfig },
    data() {
        return {
            projectNo: '',
            historyDate: '',

            activeDay: 'history',
            dayList: [],

            tableUpdateKey: '',

            activeRule: null,
            isShowEditOppoConfig: false,
            disabledProvider: [],

            staffList: [],
            staffMap: null,
            providerList: [],
            providerMap: null,
        }
    },
    created() {
        const dayList = []
        for (let index = 0; index < 8; index++) {
            dayList.push({
                day: dayjs().add(index, 'day').format('YYYY-MM-DD'),
                isToday: index === 0,
            })
        }
        this.activeDay = dayList[0]['day']
        this.dayList = dayList
        this.getStaffListApi()
        this.xbProviderListApi()
    },
    methods: {
        onProjectNoChange(val) {
            this.projectNo = val
            this.getProjectStaffListApi()
        },

        onUpdatedisabledProvider(val) {
            this.disabledProvider = val
        },
        onShowEditOppoConfig(rule) {
            this.activeRule = rule
            this.isShowEditOppoConfig = true
        },
        onCloseEditOppoConfig(status) {
            this.activeRule = null
            this.isShowEditOppoConfig = false
            if (status) {
                this.tableUpdateKey = uuidv4()
            }
        },
        getStaffListApi() {
            getStaffList({}).then((res) => {
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.id] = item
                })
                this.staffMap = _map
            })
        },
        getProjectStaffListApi() {
            getStaffList({ projectNo: this.projectNo }).then((res) => {
                this.staffList = res.data
            })
        },
        xbProviderListApi() {
            xbProviderList({}).then((res) => {
                this.providerList = res.data
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.providerNo] = item.name
                })
                this.providerMap = _map
            })
        },
    },
}
</script>
<style lang="less" scoped>
.page_container {
    min-height: 420px;
}
.today_tab {
    display: inline-block;
    color: #f00;
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid #f00;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    line-height: 16px;
    text-align: center;
    margin: 0 6px;
}
.page_container {
    position: relative;
}

.opts {
    display: flex;
    padding: 0 10px 10px;

    .el-date-editor,
    .el-button {
        margin-left: 20px;
    }
}

/deep/ .el-tabs {
    height: 100%;
}
/deep/ .el-table {
    .edit_btn {
        cursor: pointer;
        margin: 0 6px;
        color: #409eff;
    }
    .group_name {
        color: #999;
        transform: scale(0.9);
        font-size: 12px;
        display: inline-block;
    }
    .el-icon-edit {
        cursor: pointer;
        font-size: 14px;
    }
    .today {
        color: #409eff;
        font-weight: 700;
    }
}
/deep/ .empty {
    width: 100%;
    img {
        display: block;
        width: 240px;
        margin: 30px auto;
    }
}
/deep/ .provider_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 200px;
    span {
        color: #000;
        line-height: 1.4em;
        padding: 0 4px;
        &:nth-child(2n) {
            color: #666;
        }
    }
}
</style>
